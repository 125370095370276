import {Routes} from '@angular/router';


export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../layouts/components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'general/admin',
    loadChildren: () => import('../../layouts/components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path:'general/branch',
    loadChildren: () => import('../../layouts/components/branches/branches.module').then(m => m.BranchesModule)
  },
  {
    path: 'library',
    loadChildren: () => import('../../layouts/components/library/library.module').then(m => m.LibraryModule)
  },

  {
    path: 'general/session',
    loadChildren: () => import('../../layouts/components/session/session.module').then(m => m.SessionModule)
  },
  {
    path : 'modules/course',
    loadChildren : () => import('../../layouts/components/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'modules/subject',
    loadChildren : () => import('../../layouts/components/subjects/subjects.module').then(m => m.SubjectsModule)
  },
  {
    path: 'modules/batch',
    loadChildren : () => import('../../layouts/components/batches/batches.module').then(m => m.BatchesModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('../../layouts/components/employee/employee.module').then(m => m.EmployeeModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('../../layouts/components/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'students',
    loadChildren: () => import('../../layouts/components/student/student.module').then(m => m.StudentModule)
  },

  {
    path: 'hostel',
    loadChildren: () => import('../../layouts/components/hostel/hostel.module').then(m => m.HostelModule)
  },
  {
    path: 'modules/class',
    loadChildren: () => import('../../layouts/components/class/class.module').then(m => m.ClassModule)
  },
  {
    path: 'book',
    loadChildren: () => import('../../layouts/components/book/book.module').then(m => m.BookModule)
  },
  {
    path: 'library',
    loadChildren: () => import('../../layouts/components/library/library.module').then(m => m.LibraryModule)
  },
  {
    path: 'holiday',
    loadChildren: () => import('../../layouts/components/holiday/holiday.module').then(m => m.HolidayModule)
  },
  {
    path : 'attendance',
    loadChildren:() => import('../../layouts/components/attendence/attendence.module').then(m => m.AttendenceModule)
  },
  {
    path : 'message',
    loadChildren:() => import('../../layouts/components/message/message.module').then(m => m.MessageModule)
  },
  {
    path : 'ticket',
    loadChildren:()=> import('../../layouts/components/support-ticket/support-ticket.module').then(m => m.SupportTicketModule)
  },
  {
    path :'exam',
    loadChildren:()=> import('../../layouts/components/exam/exam.module').then(m => m.ExamModule)
  },
  {
    path : 'profile',
    loadChildren : () => import('../../layouts/components/profile/profile.module').then(m => m.ProfileModule)
  }

  /*{
    path: 'apps',
    loadChildren: () => import('../../layouts/components/app/app.module').then(m => m.AppModule)
  },*/
,
  {
    path : 'employee/designation',
    loadChildren : ()=> import('../../layouts/components/designation/designation.module').then(m => m.DesignationModule)
  },
  {
    path : 'notification',
    loadChildren : ()  => import('../../layouts/components/notification/notification.module').then(m => m.NotificationModule)
  }
];

export const landing: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/pages-list/Landing/Landing.module').then(m => m.LandingModule)
  }

];
