import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileImageService {

  private imageSubject = new Subject<string>();

  constructor() { }

  shareData(data: string){
    this.imageSubject.next(data);
  }

  getData(): Subject<any>{
    return this.imageSubject;
  }
}
