import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../../shared/services/auth.service';
import { GlobalService } from '../../../../shared/services/global.service';
import { PusherService } from '../../../../shared/services/pusher.service';
import { SocketService } from '../../../../shared/services/socket.service';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatTooltipModule, MatBadgeModule, FormsModule],
  providers: [AuthService, GlobalService, SocketService],
})
export class ToolbarNotificationsComponent implements OnInit {
  @ViewChild('originRef', { static: true, read: ElementRef })
  originRef?: ElementRef;
  dropdownOpen: boolean = false;
  unreadNotification: number = 0;
  user: any;

  constructor(
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef,
    private pusherService: PusherService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        console.log(this.user, 'from notification toolbar');
        this.get();  // Corrected function call
      }
    });
  }

  ngOnInit(): void {}

  get(): void {
    console.log('log from toolbar notification');
    this.notificationService.count({}).subscribe(data => {
      console.log(data);
      this.unreadNotification = data;
      this.cd.markForCheck();  // Notify Angular about the change
    });
    console.log(this.unreadNotification);
  }

  showPopover(): void {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    if (!this.originRef) {
      throw new Error('originRef undefined!');
    }

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
