import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '@vex/utils/track-by';
import { VexDateFormatRelativePipe } from '@vex/pipes/vex-date-format-relative/vex-date-format-relative.pipe';
import { Router, RouterLink } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { GlobalService } from '../../../../../shared/services/global.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { NotificationService } from '../../../../../shared/services/notification.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgFor,
    MatRippleModule,
    RouterLink,
    NgClass,
    VexDateFormatRelativePipe
  ],
  providers: [GlobalService, AuthService]
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: Notification[];
  notificationCount: number = 0;
  selectedData: any;
  originRef?: ElementRef;

  trackById = trackById;
  private dropdownOpen: boolean;

  constructor(
              private router: Router,
              private popover: VexPopoverService,
              private cd: ChangeDetectorRef,
              private notificationService : NotificationService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(): void {
  }

  get(){
    this.notificationService.count({}).subscribe(count => {
      this.notificationCount = count;
      console.log(this.notificationCount)
    })
  }
  goto(url: any): void {
    this.router.navigateByUrl(url);
  }
}
