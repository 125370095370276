import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {VexPopoverService} from '@vex/components/vex-popover/vex-popover.service';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {JsonPipe, NgIf} from '@angular/common';
import {StorageService} from '../../../../shared/services/storage.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {DataService} from '../../../../shared/services/data.service';
import { EmployeeService } from '../../../../shared/services/employee.service';
import { environment } from '../../../../../environments/environment';
import { ProfileImageService } from '../../../../shared/services/profile-image.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatRippleModule,
    MatIconModule,
    JsonPipe,
    NgIf
  ],
  providers: [
    StorageService,
    DataService
  ]
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean = false;
  user: any;
  userImageUrl: string;
  constructor(private popover: VexPopoverService,
              private cd: ChangeDetectorRef,
              private authService: AuthService,
              private dataService: DataService,
              private employeeService : EmployeeService,
              private imageService : ProfileImageService
              ) {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        this.userImageUrl = this.user.profile_image;
        this.cd.detectChanges();
      }
    });
    this.imageService.getData().subscribe(data => {
      this.userImageUrl = data;
      this.cd.detectChanges();
    });
  }

  ngOnInit() {
    this.dataService.message$.subscribe(message => {
      if (message) {
        this.authService.self().subscribe(data => {
          if (data && data.id) {
            this.user = data;
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });

    this.getSelf();
  }

  getSelf() {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        this.cd.detectChanges();
      }
    });
  }

  // getEmployee() {
  //   if (this.user && this.user['unique_id']) {
  //     this.employeeService.detail(this.user['unique_id'])
  //       .subscribe((data) => {
  //         this.userImageUrl = environment.s3_bucket_url + data.profile_img?.partial_url;
  //         console.log(this.userImageUrl);
  //         this.cd.detectChanges();
  //       }, (error) => {
  //         console.error("Failed to fetch details", error);
  //       });
  //   } else {
  //     console.error('User or unique_id is not available');
  //   }
  // }

}
