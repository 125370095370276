import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { StorageService } from './shared/services/storage.service';
import { DataService } from './shared/services/data.service';
import { GlobalService } from './shared/services/global.service';
import { AlertService } from './shared/services/alert.service';
import { HttpClient } from '@angular/common/http';
import { SocketService } from './shared/services/socket.service';
import { ChatsocketService } from './shared/services/chatsocket.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
  providers: [ChatsocketService, GlobalService, DataService]
})
export class AppComponent {
  private echo: Echo;
  window = { Pusher: null };
  userInteracted = true;
  user: any;

  constructor(
    private router: Router,
    private dataService: DataService,
    private userService: UserService
    // private chatSocket: ChatsocketService
  ) {
    console.log('app component loaded');
    // document.addEventListener(
    //   'click',
    //   () => {
    //     this.userInteracted = true;
    //   },
    //   { once: true }
    // );
    this.user = StorageService.getItem('self');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const contentContainer =
          document.querySelector('.mat-sidenav-content') || document.body;
        contentContainer.scrollTop = 0;
      }
    });
    this.connectToSocket();
    this.updateLoginStatusActive();
  }

  updateLoginStatusActive() {
    console.log('load ');
    if (this.user && this.user.id) {
      this.userService.loginStatus({ login_status: 'ACTIVE' }).subscribe(
        (data) => {},
        (error) => {}
      );
    } else {
    }
  }

  connectToSocket() {
    this.window.Pusher = Pusher;
    this.echo = new Echo({
      broadcaster: 'pusher',
      key: '93749af5fc1ecf9ab874',
      cluster: 'ap2',
      forceTLS: true
    });
    this.listenToChat();
  }

  listenToChat() {
    const self = StorageService.getItem('self');
    this.echo
      .channel(`chat_app_${self?.user_id}`)
      .listen('.message.sent', (data: any) => {
        // this.dataService.sendMessage(data);
        this.playNotificationSound();
      });
  }

  playNotificationSound() {
    if (this.userInteracted) {
      const audio = new Audio('assets/sounds/notification.mp3');
      audio
        .play()
        .catch((error) => console.error('Failed to play audio:', error));
    } else {
      console.warn(
        'Sound not played: user has not interacted with the document yet.'
      );
    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // updateLoginStatusINActive() {
  //   if (this.user && this.user.id) {
  //     this.userService.loginStatus({ login_status: 'INACTIVE' }).subscribe(
  //       (data) => {},
  //       (error) => {}
  //     );
  //   } else {
  //   }
  // }
}
