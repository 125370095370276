import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { AppUrl } from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  addEmployeeNotification(data: {}):Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_NOTIFICATION(), data);
  }
  getEmployeeNotification(data : {}) : Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE_NOTIFICATION(), data);
  }

  getNotificationForEmployee(data: {}, uniqueId:any):Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE_NOTIFICATION(uniqueId), data);
  }

  addStudentNotification(data: {}):Observable<any> {
    return this.postRequest(AppUrl.STUDENT_NOTIFICATION(), data);
  }
  getStudentNotification(data:{}):Observable<any> {
    return this.getRequest(AppUrl.STUDENT_NOTIFICATION(), data);
  }
  // status(data: {}, uniqueId: any): Observable<any> {
  //   return this.putRequest(AppUrl.DEPARTMENT_STATUS(uniqueId), data);
  // }

  count(data : {}) : Observable<any> {
    return this.getRequest(AppUrl.NOTIFICATION_COUNT(), data);
  }

  delete(uniqueId:any):Observable<any> {
    return this.deleteRequest(AppUrl.EMPLOYEE_NOTIFICATION(uniqueId));
  }
}
