<nav class="bg-white p-4 flex items-center justify-between sticky top-0 z-50">
  <div class="flex items-center justify-between lg:justify-center w-full lg:w-auto">
    <img src="./assets/logo-vol.png" alt="Logo" class="w-[80px] cursor-pointer" (click)="goTo('/')">
    <mat-icon class="icon-sm lg:hidden text-black pr-7" svgIcon="mat:menu" (click)="toggleMenu()"
              [ngClass]="{'hidden': isMenuOpen, 'block': !isMenuOpen}"></mat-icon>
  </div>


  <!-- Navigation Links -->
  <div class="hidden lg:flex lg:items-center space-x-4 subheading-2">
    <a (click)="goTo('/about-us')" class="text-black cursor-pointer">
      About Us
    </a>
    <a (click)="goTo('/pricing-plans')" class="text-black text-hover-effect cursor-pointer">
      Pricing & Plans
    </a>
    <!--<a href="/our-services" class="text-black text-hover-effect cursor-pointer   ">Services</a>-->
    <a (click)="goTo('/features')" class="text-black text-hover-effect cursor-pointer ">
      Features
    </a>
    <!--    <a href="/resources" class="text-black   ">Resources</a>-->
    <!--<a href="/contact-sales" class="bg-blue text-white rounded-md  px-3 py-2 flex items-center">
      Contact Sales
      <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
    </a>-->
    <a (click)="goTo('/auth/register')"
       class="bg-blue  rounded-md  px-3 py-2 flex items-center whitespace-nowrap  text-white highlight-button ">
      <mat-icon class="icon-sm mr-3px" svgIcon="mat:smartphone"></mat-icon>
      Register
    </a>
    <a (click)="goTo(loginStatus?'home':'auth/login')"
       class="bg-blue rounded-md  px-3 py-2 flex items-center whitespace-nowrap text-white highlight-button ">
      <mat-icon class="icon-sm mr-3px" svgIcon="mat:exit_to_app"></mat-icon>
      {{ loginStatus ? 'Go To Dashboard' : 'Login To Dashboard' }}
    </a>
  </div>

  <!-- Mobile Menu -->
  <div [ngClass]="{'menu-open': isMenuOpen, 'menu-closed': !isMenuOpen}"
       class="fixed top-0 w-[80%] sm:w-1/2 h-full shadow bg-white lg:hidden transform smooth z-50">
    <div class="flex justify-between border-b items-center py-2 pr-3 shadow-b">
      <img src="./assets/images/brand/logo.png" alt="Logo" class="h-6rem w-auto cursor-pointer" (click)="goTo('/')">
      <i class="fa-solid fa-xmark text-lg primary-icon" (click)="toggleMenu()"></i>
    </div>
    <ul class="list-none w-full p-4">
      <li class="py-3 card my-1 px-2" [routerLink]="['/about-us']"><a class="text-lg  text-gray-800 font-semibold" >About Us</a></li>
      <li class="py-3 card my-1 px-2" [routerLink]="['/pricing-plans']"><a class="text-lg  text-gray-800 font-semibold" >Pricing Plans</a></li>
      <li class="py-3 card my-1 px-2" [routerLink]="['/features']"><a class="text-lg  text-gray-800 font-semibold" >Features</a></li>
    </ul>
    <div class="px-4 flex flex-wrap gap-4">
      <button style="width: 100%" class="py-3 mx-auto search-btn card my-2">
        <a class="text-lg text-white font-semibold" [routerLink]="['/auth/register']">Register</a>
      </button>
      <button style="width: 100%;" class="py-3 mx-auto search-btn card">
        <a class="text-lg text-white justify-center flex items-center font-semibold" (click)="goTo(loginStatus ? 'home' : 'auth/login')">
          {{ loginStatus ? 'Go To Dashboard' : 'Login To Dashboard' }}
          <mat-icon class="icon-sm" svgIcon="mat:exit_to_app"></mat-icon>
        </a>
      </button>
    </div>
    <div class="text-[10px] w-full fixed bottom-0 text-center subheading-2">
      <span [style.font-family]="'var(--vex-font)'">© Copyright {{ currentYear }}. All rights reserved.</span>
      <br>
      <span class="text-info cursor-pointer" (click)="openUrl('https://volmint.com')" [style.font-family]="'var(--vex-font)'"> Volmint Technologies Private Limited.</span>
    </div>
  </div>
</nav>



