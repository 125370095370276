import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, concatAll, Observable } from 'rxjs';
import { PermissionConstant } from '../../shared/constants/permission-constant';
import { EmployeeService } from '../../shared/services/employee.service';
import { AuthService } from '../../shared/services/auth.service';
import { Helper } from '../../shared/constants/helper';
import { coerceStringArray } from '@angular/cdk/coercion';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  user: any;
  userData: any;
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(
    private readonly layoutService: VexLayoutService,
    private authService: AuthService,
    private employeeService: EmployeeService
  ) {
    const self = localStorage.getItem('self');
    if(self){
      this.authService.self().subscribe((data) => {
        if (data && data.id) {
          this.user = data;
          if (this.user && this.user['user_type'] === 'EMPLOYEE') {
            this.checkUserDetails();
          }else {
            this.loadNavigation();
          }
        }
      });
    }else {
      this.loadNavigation();
    }
  }

  ngOnInit(): void {

  }
  loadNavigation(): void {
    console.log('navigation')
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboard',
        availableTo: [PermissionConstant.ALL],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/home',
            icon: 'mat:home',
            routerLinkActiveOptions: { exact: true },
            availableTo: [PermissionConstant.ALL],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'General',
        availableTo: [PermissionConstant.GENERAL],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Branches',
            icon: 'mat:spa',
            availableTo: [PermissionConstant.BRANCH],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: 'general/branch/add',
                availableTo: [PermissionConstant.ADD_BRANCH],
                is_hidden: false
              },

              {
                type: 'link',
                label: 'List',
                route: 'general/branch/list',
                availableTo: [PermissionConstant.LIST_BRANCH],
                is_hidden: false
              },
            ]
          },
          {
            type: 'dropdown',
            label: 'Admins',
            icon: 'mat:admin_panel_settings',
            availableTo: [PermissionConstant.ADMIN],
            is_hidden: (this.user && this.user.user_type && this.user.user_type != 'SUPER_ADMIN'),
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/admin/add',
                availableTo: [PermissionConstant.ADD_ADMIN],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/admin/list',
                availableTo: [PermissionConstant.LIST_ADMIN],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Sessions',
            icon: 'mat:access_time',
            availableTo: [PermissionConstant.SESSION],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/session/add',
                availableTo: [PermissionConstant.ADD_SESSION],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/session/list',
                availableTo: [PermissionConstant.LIST_SESSION],
                is_hidden: false
              }
            ]
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Modules',
        availableTo: [PermissionConstant.MODULE],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Subject',
            icon: 'mat:book',
            availableTo: [PermissionConstant.SUBJECT],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/modules/subject/add',
                availableTo: [PermissionConstant.ADD_SUBJECTS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/modules/subject/list',
                availableTo: [PermissionConstant.LIST_SUBJECTS],
                is_hidden: false
              }
            ]
          },

          {
            type: 'dropdown',
            label: 'Course',
            icon: 'mat:how_to_vote',
            availableTo: [PermissionConstant.COURSE],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/modules/course/add',
                availableTo: [PermissionConstant.ADD_COURSE],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/modules/course/list',
                availableTo: [PermissionConstant.LIST_COURSE],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Batches',
            icon: 'mat:local_library',
            availableTo: [PermissionConstant.BATCH],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/modules/batch/add',
                availableTo: [PermissionConstant.ADD_BATCH],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/modules/batch/list',
                availableTo: [PermissionConstant.LIST_BATCH],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Classes',
            icon: 'mat:streetview',
            availableTo: [PermissionConstant.CLASS],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/modules/class/add',
                availableTo: [PermissionConstant.ADD_CLASS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/modules/class/list',
                availableTo: [PermissionConstant.LIST_CLASS],
                is_hidden: false
              }
            ]
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Employee',
        availableTo: [PermissionConstant.EMPLOYEE],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Department',
            route: '/employee/department',
            icon: 'mat:category',
            availableTo: [PermissionConstant.DEPARTMENT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Designation',
            route: '/employee/designation',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.DESIGNATION],
            is_hidden: false
          },
          {
            type: 'dropdown',
            label: 'Employee',
            icon: 'mat:streetview',
            availableTo: [PermissionConstant.EMPLOYEES_EMPLOYEE],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/employee/add',
                availableTo: [PermissionConstant.ADD_EMPLOYEES],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/employee/list',
                availableTo: [PermissionConstant.LIST_EMPLOYEES],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Attendance',
                route: '/attendance/employee',
                icon: 'mat:category',
                availableTo: [PermissionConstant.ATTENDANCE_EMPLOYEES],
                is_hidden: false
              }
            ]
          },
          {
            type: 'link',
            label: 'Permissions',
            route: '/employee/permissions',
            icon: 'mat:key',
            availableTo: [PermissionConstant.ALL],
            is_hidden: false
          },


        ]
      },
      {
        type: 'subheading',
        label: 'Students',
        availableTo: [PermissionConstant.STUDENT],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Students',
            icon: 'mat:person_add',
            availableTo: [PermissionConstant.STUDENT_STUDENT],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/students/add',
                icon: 'mat:person_add',
                availableTo: [PermissionConstant.STUDENT_ADD],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/students/list',
                icon: 'mat:diversity_3',
                availableTo: [PermissionConstant.STUDENT_LIST],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Attendance',
                route: '/attendance/student',
                icon: 'mat:inventory',
                availableTo: [PermissionConstant.STUDENT_ATTENDANCE],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Transactions',
                route: '/students/transactions',
                icon: 'mat:receipt',
                availableTo: [PermissionConstant.STUDENT_TRANSACTION],
                is_hidden: false
              }
            ]
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Exams',
        availableTo: [PermissionConstant.EXAM],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Exam Session',
            route: '/exam/exam-session',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.EXAM_SESSION],
            is_hidden: false
          },
      {
        type: 'link',
        label: 'Scheduled Exam',
        route: '/exam/schedule-exam',
        icon: 'mat:diversity_3',
        availableTo: [PermissionConstant.EXAM_SCHEDULE],
        is_hidden: false
      },

      {
            type: 'link',
            label: 'Result',
            route: '/exam/result',
            icon: 'mat:inventory',
            availableTo: [PermissionConstant.EXAM_RESULT],
            is_hidden: false
          },
        ]
      },

      {
        type: 'subheading',
        label: 'Library',
        availableTo: [PermissionConstant.LIBRARY],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Borrowings',
            route: '/library/borrowing',
            icon: 'mat:library_books',
            availableTo: [PermissionConstant.LIBRARY_BORROWING],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/library/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.LIBRARY_TRANSACTION],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Utilities',
        availableTo: [PermissionConstant.UTILITIES],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Hostels',
            route: '/hostel/list',
            icon: 'mat:gite',
            availableTo: [PermissionConstant.UTILITIES_HOSTEL],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Holidays',
            route: '/holiday/list',
            icon: 'mat:holiday_village',
            availableTo: [PermissionConstant.UTILITIES_HOLIDAY],
            is_hidden: false
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Books',
        availableTo: [PermissionConstant.BOOK],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Categories',
            route: '/book/category',
            icon: 'mat:category',
            availableTo: [PermissionConstant.BOOK_CATEGORY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'List',
            route: '/book/list',
            icon: 'mat:inventory',
            availableTo: [PermissionConstant.BOOK_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/book/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.BOOK_TRANSACTION],
            is_hidden: false
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Inventory',
        availableTo: [PermissionConstant.INVENTORY],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Categories',
            route: '/inventory/category',
            icon: 'mat:category',
            availableTo: [PermissionConstant.INVENTORY_CATEGORY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'List',
            route: '/inventory/list',
            icon: 'mat:inventory',
            availableTo: [PermissionConstant.INVENTORY_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/inventory/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.INVENTORY_TRANSACTION],
            is_hidden: false
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Message',
        availableTo: [PermissionConstant.MESSAGE],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Message',
            route: '/message',
            icon: 'mat:gite',
            availableTo: [PermissionConstant.MESSAGE],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Tickets',
        availableTo: [PermissionConstant.TICKET],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Support',
            availableTo: [PermissionConstant.SUPPORT_TICKET],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Admission',
                route: 'ticket/admission',
                icon: 'mat:contact_mail',
                availableTo: [PermissionConstant.ADMISSION_TICKET],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Certificate',
                route: 'ticket/certificate',
                icon: 'mat:contact_mail',
                availableTo: [PermissionConstant.CERTIFICATE_TICKET],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Examination',
                route: 'ticket/examination',
                icon: 'mat:contact_mail',
                availableTo: [PermissionConstant.EXAMINATION_TICKET],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Other',
                route: 'ticket/other',
                icon: 'mat:contact_mail',
                availableTo: [PermissionConstant.OTHER_TICKET],
                is_hidden: false
              }
            ]
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Notifications',
        availableTo: [PermissionConstant.NOTIFICATION],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Employee Notification',
            route: '/notification/notification-for-employee',
            icon: 'mat:notifications',
            availableTo: [PermissionConstant.EMPLOYEE_NOTIFICATION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Student Notification',
            route: '/notification/notification-for-student',
            icon: 'mat:notifications',
            availableTo: [PermissionConstant.STUDENT_NOTIFICATION],
            is_hidden: false
          },
          // {
          //   type: 'link',
          //   label: 'Center\'s Notification',
          //   route: '/notification/center-notification',
          //   icon: 'mat:notifications',
          //   availableTo: [PermissionConstant.CENTER_NOTIFICATION],
          //   is_hidden: false
          // },
          // {
          //   type: 'link',
          //   label: 'Report',
          //   route: '/notification/report',
          //   icon: 'mat:dashboard_customize',
          //   availableTo: [PermissionConstant.NOTIFICATION_REPORT],
          //   is_hidden: false
          // }
        ]
      }

    ]);
  }

  getSelfDetails() {
    this.authService.self().subscribe((data) => {
      if (data && data.id) {
        this.user = data;
        if (this.user && this.user['user_type'] === 'EMPLOYEE') {
          this.checkUserDetails();
        }
      }
    });
  }

  checkUserDetails() {
    this.employeeService.detail(this.user.unique_id).subscribe((data) => {
      console.log(data)
      if (data && data['employee_details'] && data['employee_details']['designation'] && data['employee_details']['designation']['designation_permissions']) {
        this.userData = data['employee_details']['designation']['designation_permissions'];
        console.log(this.userData)
        let rolePermissions = [];
        if (this.userData && this.userData.length > 0) {
          this.userData.forEach(subPermission => {
            if (subPermission && subPermission['sub_permission']) {
              const subPermMeta = subPermission['sub_permission']['meta'];
              const parentPermMeta = subPermission['sub_permission']['permission']
                ? subPermission['sub_permission']['permission']['meta']
                : null;
              if (subPermMeta) {
                rolePermissions.push(subPermMeta);
              }
              if (parentPermMeta) {
                rolePermissions.push(parentPermMeta);
              }
            }
          });
        }
        this.loadNavigation();
        this._items.forEach(menu => {
          menu.forEach((item) => {
            if(item && item['availableTo'] && !item['availableTo'].includes('ALL')) {
              item['is_hidden'] = !Helper.findCommonElements(item['availableTo'], rolePermissions, );
              if (item['children'] && item['children'].length > 0) {
                item['children'].forEach(child => {
                  child['is_hidden'] = !Helper.findCommonElements(child.availableTo, rolePermissions);
                });
              }
            } else {
              item['is_hidden'] = false;
            }
          });
        });
      }
    });
  }
}
