import {Injectable} from '@angular/core';

@Injectable()
export class PermissionConstant {
  static ALL = 'ALL';

  // Dashboard
  static HOME = 'HOME';

  // General
  static GENERAL = 'GENERALS';
  static ADMIN = 'GENERALS_ADMINS';
  static ADD_ADMIN = 'GENERALS_ADD_ADMINS';
  static LIST_ADMIN = 'GENERALS_LIST_ADMINS';
  static BRANCH = 'GENERALS_BRANCH'
  static ADD_BRANCH = 'GENERALS_ADD_BRANCH';
  static LIST_BRANCH = 'GENERALS_LIST_BRANCH';
  static SESSION = 'GENERALS_SESSIONS';
  static ADD_SESSION = 'GENERALS_ADD_SESSIONS';
  static LIST_SESSION = 'GENERALS_LIST_SESSIONS';
  static GENERAL_REPORT = '';

  // Employee
  static EMPLOYEE = 'EMPLOYEES';
  static EMPLOYEES_EMPLOYEE = 'EMPLOYEES_EMPLOYEES';
  static ADD_EMPLOYEES = 'EMPLOYEES_ADD_EMPLOYEES';
  static LIST_EMPLOYEES = 'EMPLOYEES_LIST_EMPLOYEES';
  static ATTENDANCE_EMPLOYEES = 'EMPLOYEES_ATTENDANCE_EMPLOYEES';
  static DEPARTMENT = 'EMPLOYEES_DEPARTMENTS';
  static DESIGNATION = 'EMPLOYEES_DESIGNATIONS';
  static EMPLOYEES = 'EMPLOYEES_EMPLOYEES';

  static PERMISSION = '';
  static EMPLOYEE_REPORT = '';

  // Module
  static MODULE = 'MODULES';

  static SUBJECT = 'MODULES_SUBJECTS';
  static ADD_SUBJECTS = 'MODULES_ADD_SUBJECTS';
  static LIST_SUBJECTS = 'MODULES_LIST_SUBJECTS';

  static COURSE = 'MODULES_COURSES';
  static ADD_COURSE = 'MODULES_ADD_COURSES';
  static LIST_COURSE = 'MODULES_LIST_COURSES';

  static BATCH = 'MODULES_BATCHES';
  static ADD_BATCH = 'MODULES_ADD_BATCHES';
  static LIST_BATCH = 'MODULES_LIST_BATCHES';

  static CLASS = 'MODULES_CLASSES';
  static ADD_CLASS = 'MODULES_ADD_CLASSES';
  static LIST_CLASS = 'MODULES_LIST_CLASSES';


  // Inventory
  static INVENTORY = 'INVENTORYS';
  static INVENTORY_CATEGORY = 'INVENTORYS_CATEGORIES';
  static INVENTORY_LIST = 'INVENTORYS_LIST';
  static INVENTORY_TRANSACTION = 'INVENTORYS_TRANSACTIONS';
  static INVENTORY_REPORT = 'INVENTORY_REPORTS';

  // Book
  static BOOK = 'BOOKS';
  static BOOK_CATEGORY = 'BOOKS_CATEGORIES';
  static BOOK_LIST = 'BOOKS_LIST';
  static BOOK_TRANSACTION = 'BOOKS_TRANSACTIONS';
  static BOOK_REPORT = '';

  // Library
  static LIBRARY = 'LIBRARIES';
  static LIBRARY_BORROWING = 'LIBRARIES_BORROWINGS';
  static LIBRARY_TRANSACTION = 'LIBRARIES_TRANSACTIONS';
  static LIBRARY_REPORT = '';

  // Student
  static STUDENT = 'STUDENTS';
  static STUDENT_STUDENT = 'STUDENTS_STUDENT';
  static STUDENT_ADD = 'STUDENTS_ADD';
  static STUDENT_LIST = 'STUDENTS_LIST';
  static STUDENT_TRANSACTION = 'STUDENTS_TRANSACTIONS';
  static STUDENT_ATTENDANCE = 'STUDENTS_ATTENDANCE';

  // EXAM

  static EXAM = 'EXAMS';
  static EXAM_SESSION = 'EXAM_SESSIONS';
  static EXAM_RESULT = 'EXAM_RESULT';
  static EXAM_SCHEDULE = 'EXAM_SCHEDULE';




  // Account
  static ACCOUNT = 'ACCOUNTS';
  static ACCOUNT_RECEIPT = 'ACCOUNTS_RECEIPT';
  static ACCOUNT_RECEIPT_TRANSACTION = 'ACCOUNTS_RECEIPT_TRANSACTION';
  static ACCOUNT_WALLET_TRANSACTION = 'ACCOUNTS_WALLET_TRANSACTION';
  static ACCOUNT_REPORT = '';

  // Utilities
  static UTILITIES = 'UTILITIES';
  static UTILITIES_HOSTEL = 'UTILITIES_HOSTELS';
  static UTILITIES_HOLIDAY = 'UTILITIES_HOLIDAYS';
  static UTILITIES_REPORT = '';


  // Messsage
  static  MESSAGE = 'MESSAGE';
  static  MESSAGE_MSG = 'MESSAGE_MSGS';


  // Support Ticket
  static TICKET = 'TICKETS'
  static SUPPORT_TICKET = 'SUPPORT_TICKET';
  static ADMISSION_TICKET = 'TICKETS_ADMISSION_SUPPORT_TICKET';
  static EXAMINATION_TICKET = 'TICKETS_EXAMINATION_SUPPORT_TICKET';
  static CERTIFICATE_TICKET = 'TICKETS_CERTIFICATE_SUPPORT_TICKET';
  static OTHER_TICKET = 'TICKETS_OTHER_SUPPORT_TICKET';

  static NOTIFICATION = 'NOTIFICATION';
  static  NOTIFICATION_LIST = 'NOTIFICATION_LIST'
  static EMPLOYEE_NOTIFICATION  = 'EMPLOYEE_NOTIFICATION';
  static STUDENT_NOTIFICATION = 'STUDENT_NOTIFICATION';
}
