import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class AppUrl {
  static get ADMIN_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get LOGIN(): string {
    return AppUrl.ADMIN_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.ADMIN_URL + 'self';
  }

  static UPDATE_PASSWORD(): string {
    return AppUrl.ADMIN_URL + 'update-password';
  }

  static UPDATE_SELF(): string {
    return AppUrl.ADMIN_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.ADMIN_URL + 'logout';
  }

  static UPDATE_PROFILE_IMAGE(uniqueId): string {
    return AppUrl.ADMIN_URL + 'admin-profile-image/' + uniqueId;
  }

  static FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-upload';
  }

  static MULTIPLE_FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-multiple-upload';
  }

  static CHAT_FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-chat-upload';
  }

  static COUNTRY(): string {
    return AppUrl.ADMIN_URL + 'countries';
  }

  static USERS(): string {
    return AppUrl.ADMIN_URL + 'user';
  }

  static STATES(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'states/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'states';
  }

  static ADMIN_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'admin-counts';
  }

  static ADMIN_WISE_ADMISSIONS(): string {
    return AppUrl.ADMIN_URL + 'admin-wise-admissions';
  }

  static ADMIN(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'admin/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'admin';
    }
  }

  static ADMIN_COUNT(): string {
    return AppUrl.ADMIN_URL + 'admin-count';
  }

  static ADMIN_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-status/' + unique_id;
  }

  static ADMIN_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-password/' + unique_id;
  }

  static PROGRAM(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'program/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'program';
  }

  static PROGRAM_COUNT(): string {
    return AppUrl.ADMIN_URL + 'program-count';
  }

  static PROGRAM_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'program-status/' + uniqueId;
  }

  static INVENTORY_CATEGORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory-category/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-category';
  }

  static INVENTORY_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-category-count';
  }

  static INVENTORY_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-category-status/' + uniqueId;
  }

  static INVENTORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory';
  }

  static INVENTORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-counts';
  }

  static INVENTORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-status/' + uniqueId;
  }

  static INVENTORY_STOCK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory-stock/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-stock';
  }

  static INVENTORY_STOCK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-count';
  }

  static INVENTORY_STOCK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-status/' + uniqueId;
  }

  static INVENTORY_STOCK_INVOICE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-invoice-id/' + uniqueId;
  }

  static INVENTORY_TRANSACTION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'inventory-stock-transaction/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-stock-transaction';
  }

  static STUDENT_TRANSACTION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'student-transaction/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'student-transaction';
    }
  }

  static INVENTORY_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-transaction-status/' + uniqueId;
  }

  static COURSE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'course/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'course';
  }

  static COURSE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'course-count';
  }

  static COURSE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-status/' + uniqueId;
  }

  static BRANCH(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'branch/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'branch';
  }

  static BRANCH_COUNT(): string {
    return AppUrl.ADMIN_URL + 'branch-count';
  }

  static BRANCH_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'branch-status/' + uniqueId;
  }

  static BOOKS_CATEGORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book-category/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-category';
  }

  static BOOKS_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-category-count';
  }

  static BOOKS_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-category-status/' + uniqueId;
  }

  static BOOK_CATEGORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book-category/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-category';
  }

  static BOOK_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-category-count';
  }

  static BOOK_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-category-status/' + uniqueId;
  }

  static BOOK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book';
  }

  static BOOK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-count';
  }

  static BOOK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-status/' + uniqueId;
  }

  static BOOK_COVER_IMAGE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-cover-image-id/' + uniqueId;
  }

  static BOOK_STOCK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book-stock/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-stock';
  }

  static BOOK_STOCK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-stock-count';
  }

  static BOOK_STOCK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-status/' + uniqueId;
  }

  static BOOK_STOCK_INVOICE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-invoice-id/' + uniqueId;
  }

  static BOOK_TRANSACTION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'book-stock-transaction/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-stock-transaction';
  }

  static BOOK_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-transaction-status/' + uniqueId;
  }

  static BOOK_LIBRARY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'library/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'library';
  }

  static BOOK_RETURN(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-return/' + uniqueId;
  }

  static BOOK_LIBRARY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-status/' + uniqueId;
  }

  static CHAT(chatIds?:any): string {
    if (chatIds) {
      return AppUrl.ADMIN_URL + 'chat/' + chatIds;
    } else {
      return AppUrl.ADMIN_URL + 'chat';
    }
  }

  static UNREAD_CHAT(): string {
    return AppUrl.ADMIN_URL + 'chat-unreadmsg';
  }

  static BOOK_LIBRARY_TRANSACTION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'library-transaction/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'library-transaction';
  }

  static BOOK_LIBRARY_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-transaction-status/' + uniqueId;
  }

  static HOSTEL(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel';
    }
  }

  static HOSTEL_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-count';
  }

  static HOSTEL_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-status/' + uniqueId;
  }

  static HOSTEL_ROOM(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel-room/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel-room';
    }
  }

  static HOSTEL_ROOM_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-room-count';
  }

  static HOSTEL_ROOM_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-room-status/' + uniqueId;
  }

  static HOSTEL_MENU(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel-menu/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel-menu';
    }
  }

  static HOSTEL_MENU_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-menu-count';
  }

  static HOSTEL_MENU_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-menu-status/' + uniqueId;
  }

  static SESSION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'session';
  }

  static DEFAULT_SESSION(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'default-session/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'default-session';
    }
  }

  static SESSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'session-count';
  }

  static SESSION_STATUS(uniqueId: any, status: any): string {
    if (status == 'default_session')
      return AppUrl.ADMIN_URL + 'default-session';
    else if (status == 're-registration-session')
      return AppUrl.ADMIN_URL + 're-registration-session/' + uniqueId;
    else if (status == 're-registration-entry-session')
      return AppUrl.ADMIN_URL + 're-registration-entry-session/' + uniqueId;
    else if (status == 'admission-session')
      return AppUrl.ADMIN_URL + 'admission-session/' + uniqueId;
    else if (status == 're-opened-session')
      return AppUrl.ADMIN_URL + 're-opened-session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'session-status/' + uniqueId;
  }

  static PCB_MAPPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcb-mapping/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcb-mapping';
  }

  static PCB_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-status/' + uniqueId;
  }

  static PCBS_MAPPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcbs-mapping/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcbs-mapping';
  }

  static PCBS_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcbs-mapping-status/' + uniqueId;
  }

  static FEE_STRUCTURE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'fee-structure/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'fee-structure';
    }
  }

  static FEE_STRUCTURE_PCB(): string {
    return AppUrl.ADMIN_URL + 'fee-structure-by-pcb';
  }

  static SUBJECT(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'subject/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'subject';
  }

  static SUBJECT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'subject-count';
  }

  static SUBJECT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-status/' + uniqueId;
  }

  static STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'student/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'student';
    }
  }
static STUDENT_ASSIGNMENT(){
    return AppUrl.ADMIN_URL + 'student-assignment';
}
  static STUDENT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'student-counts';
  }
  static BOOK_COUNTS_FOR_HOME(): string{
    return AppUrl.ADMIN_URL + 'book-counts'
  }

  static STUDENT_PERSONAL_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-personal-info/' + uniqueId;
  }

  static STUDENT_PHOTOGRAPH(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-personal-info-photograph/' + uniqueId;
  }

  static STUDENT_GOVT_PHOTO_ID(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'student-personal-info-govt-photo-id-proof/' + uniqueId
    );
  }

  static STUDENT_SIGNATURE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'student-personal-info-student-signature/' + uniqueId
    );
  }

  static STUDENT_ADDRESS_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-address/' + uniqueId;
  }

  static STUDENT_ACADEMIC_DETAIL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details/' + uniqueId;
  }

  static STUDENT_TENTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details-tenth-certificate/' + uniqueId;
  }

  static STUDENT_TWELTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details-twelth-certificate/' + uniqueId;
  }

  static STUDENT_MORE_QUALIFICATION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'more-qualification/' + uniqueId;
  }

  static STUDENT_PREVIOUS_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'more-qualification-previous-certificate/' + uniqueId
    );
  }

  static STUDENT_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'more-qualification-graduation-certificate/' + uniqueId
    );
  }

  static STUDENT_POST_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'more-qualification-post-graduation-certificate/' +
      uniqueId
    );
  }

  static STUDENT_OTHER_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'more-qualification-other-qualification-certificate/' +
      uniqueId
    );
  }

  static STUDENT_COURSE_DETAIL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-details/' + uniqueId;
  }

  static STUDENT_BATCH_DETAIL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-batches-details/' + uniqueId;
  }

  static STUDENT_FINALISE_FEES(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-finalised-fees/' + uniqueId;
  }

 static STUDENT_COURSE_STATUS(uniqueId: any):string{
    return AppUrl.ADMIN_URL + 'student-course-status/' + uniqueId;
  }
  static STUDENT_ASSIGNMENT_STATUS(uniqueId: any):string{
    return AppUrl.ADMIN_URL + 'student-assignment-status/' + uniqueId;
  }
  static STUDENT_FINALISE_FEES_INSTALLMENTS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-finalised-fees-installments/' + uniqueId;
  }

  static STUDENT_HOSTEL_ASSIGN(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-hostel-assign/' + uniqueId;
  }

  static SUBJECT_SLM_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-slm/' + uniqueId;
  }

  static SUBJECT_NOTE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-note/' + uniqueId;
  }

  static BATCH(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'batch/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'batch';
    }
  }

  static ADD(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'batch/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'batch';
    }
  }

  static BATCH_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'batch-status/' + uniqueId;
  }

  static EMPLOYEES(): string {
    return AppUrl.ADMIN_URL + 'employees/';
  }

  static CHAT_EMPLOYEES(): string {
    return AppUrl.ADMIN_URL + 'chat-employees/';
  }

  static FILTER_CHATS(): string {
    return AppUrl.ADMIN_URL + 'filter-chat/';
  }

  static EMPLOYEE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'employee/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'employee';
    }
  }

  static EMPLOYEE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-status/' + uniqueId;
  }

  static EMPLOYEE_PROFILE_IMAGE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-profile-image/' + uniqueId;
  }

  static EMPLOYEE_GOVT_PHOTO_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-govt-photo-id/' + uniqueId;
  }

  static EMPLOYEE_PAN(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-pan-image/' + uniqueId;
  }

  static EMPLOYEE_SIGNATURE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-signature/' + uniqueId;
  }

  static EMPLOYEE_PERSONAL_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-personal-info/' + uniqueId;
  }

  static EMPLOYEE_ADDRESS_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-address/' + uniqueId;
  }

  static EMPLOYEE_DEPARTMENT_DESIGNATION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-department-designations/' + uniqueId;
  }

  static EMPLOYEE_ACCOUNT_DETAILS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-account-details/' + uniqueId;
  }

  static EMPLOYEE_PAYROLL_DETAILS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-payrolls/' + uniqueId;
  }

  static EMPLOYEE_LEAVE_DETAILS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-leave-details/' + uniqueId;
  }

  static EMPLOYEE_TENTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-tenth-certificate/' + uniqueId;
  }

  static EMPLOYEE_TWELFTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-twelth-certificate/' + uniqueId;
  }

  static EMPLOYEE_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-graduation-certificate/' + uniqueId;
  }

  static EMPLOYEE_POST_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'employee-post-graduation-certificate/' + uniqueId
    );
  }

  static EMPLOYEE_HIGHEST_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'employee-highest-qualification-certificate/' +
      uniqueId
    );
  }

  static EMPLOYEE_OTHER_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'employee-other-qualification-certificate/' + uniqueId
    );
  }
  static EMPLOYEE_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-password/' + unique_id;
  }
  static EMPLOYEE_PAYROLL(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-salary/' + unique_id;
  }
  static EMPLOYEE_SALARY_SLIP(fileUrl: any): string {
    return  environment.appUrl + 'download-employee-salaryslip/' + fileUrl;
  }
  static DEPARTMENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'department/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'department';
    }
  }

  static DEPARTMENT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'department-status/' + uniqueId;
  }

  static DELETE_DEPARTMENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'department/' + uniqueId;
  }

  static DESIGNATION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'designation/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'designation';
    }
  }

  static DESIGNATION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'designation-status/' + uniqueId;
  }

  static DELETE_DESIGNATION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'designation/' + uniqueId;
  }

  static CLASS(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'classes/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'classes';
    }
  }

  static CLASS_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'classes-status/' + uniqueId;
  }

  static EMPLOYEE_ATTENDANCE(): string {
    return AppUrl.ADMIN_URL + 'employee-attendance';
  }

  static STUDENT_ATTENDANCE(): string {
    return AppUrl.ADMIN_URL + 'student-attendance';
  }

  static SUPPORT_TICKET(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'support-ticket/' + uniqueId;
    } else return AppUrl.ADMIN_URL + 'support-ticket';
  }

  static SUPPORT_TICKET_STATUS(uniqueId: any): string{
    return AppUrl.ADMIN_URL + 'support-ticket-status/' + uniqueId;
  }

  static TOPIC(uniqueId? : any):string {
    if(uniqueId){
      return AppUrl.ADMIN_URL + 'topics/' + uniqueId;
    }else {
      return AppUrl.ADMIN_URL + 'topics';
    }
  }

  static HOLIDAY(uniqueId?: any): string {
    if(uniqueId){
      return AppUrl.ADMIN_URL + 'holiday/' + uniqueId;
    }else {
      return AppUrl.ADMIN_URL + 'holiday';
    }
  }



  static SUB_PERMISSION(uniqueId?: any): string {
    if(uniqueId){
      return AppUrl.ADMIN_URL + 'sub-permission/' + uniqueId;
    }else {
      return AppUrl.ADMIN_URL + 'sub-permission';
    }
  }

  static SUB_PERMISSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'sub-permission-count';
  }

  static SUB_PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'sub-permission-status/' + uniqueId;
  }


  static PERMISSION(uniqueId?: any): string {
    if(uniqueId){
      return AppUrl.ADMIN_URL + 'permission/' + uniqueId;
    }else {
      return AppUrl.ADMIN_URL + 'permission';
    }
  }

  static PERMISSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'permission-count';
  }

  static PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'permission-status/' + uniqueId;
  }
  static DESIGNATION_PERMISSION(uniqueId?: any): string {
    if(uniqueId){
      return AppUrl.ADMIN_URL + 'designation-permission/' + uniqueId;
    }else {
      return AppUrl.ADMIN_URL + 'designation-permission';
    }
  }

  static DESIGNATION_PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'designation-permission-status/' + uniqueId;
  }

  static DESIGNATION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'designation-count';
  }
  static HOME_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'home-counts'
  }
  static GENERAL_COUNTS():string{
    return AppUrl.ADMIN_URL + 'general-counts'
  }

  static EXAM_SESSION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'exam-session/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'exam-session';
}
  }

  static EXAM_SESSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'exam-session-status/' + uniqueId;
  }

  static EXAM_SCHEDULE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'exam-schedule/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'exam-schedule';
    }
  }

  static RESULTS(){
    return AppUrl.ADMIN_URL + 'results';
  }

  static NOTIFICATION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'student-notification/' + 'uni6b642e46b74cccd75714a93592007e2dqueId';
    } else {
      return AppUrl.ADMIN_URL + 'student-notification';
    }
  }
  static EMPLOYEE_NOTIFICATION(uniqueId?:any): string {
    if (uniqueId){
          return AppUrl.ADMIN_URL + 'notification/' + uniqueId;
    }
    else return AppUrl.ADMIN_URL + 'notification';

  }


  static STUDENT_NOTIFICATION(uniqueId?:any): string {
    if (uniqueId){
      return AppUrl.ADMIN_URL + 'student-notification/' + uniqueId;
    }
    else return AppUrl.ADMIN_URL + 'student-notification';

  }

  static NOTIFICATION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'notification-count';
  }

  static LOGIN_STATUS(): string {
    return AppUrl.ADMIN_URL + 'login-status';
  }

  static RESULT_UPLOAD():string{
    return AppUrl.ADMIN_URL + 'result-excel-upload';
  }
  static STUDENT_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-password/' + unique_id;
  }
}
