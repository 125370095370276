import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
// @ts-ignore
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
// @ts-ignore
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
// @ts-ignore
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';
// @ts-ignore
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  ErrorStateMatcher,
  MatOptionModule,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { AsyncPipe, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StorageService } from '../../shared/services/storage.service';
import { AuthService } from '../../shared/services/auth.service';
import { GlobalService } from '../../shared/services/global.service';
import { UserService } from '../../shared/services/user.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AlertService } from '../../shared/services/alert.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HeaderComponent } from '../../landing/header/header.component';
import { FooterComponent } from '../../landing/footer/footer.component';
import { NavigationLoaderService } from '../../core/navigation/navigation-loader.service';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

@Component({
  selector: 'vex-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    VexPageLayoutComponent,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    MatIconModule,
    VexBreadcrumbsComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgIf,
    ReactiveFormsModule,
    NgFor,
    MatSlideToggleModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    AsyncPipe,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms],
  providers: [
    GlobalService,
    AuthService,
    UserService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  signInForm: FormGroup = new FormGroup({});
  //otpForm: FormGroup = new FormGroup({});
  inputType: any = 'password';
  visible: any = false;
  submitted: any = false;
  isBusy: any = false;
  organisations: any = [];
  formType: string = 'login';
  signInParams: any = {};
  userImageUrl: string;
  is_submitting: boolean = false;
  myControl: FormControl = new FormControl('');
  private echo: Echo;
  userInteracted = true;
  window = { Pusher: null };
  pageExtras: {} = {
    buttonType: 'search',
    imageUrl: '',
    profileImage: ''
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private alertService : AlertService,
    private userService: UserService,
    private globalService: GlobalService,
    private navigationService: NavigationLoaderService,
  ) {
    this.checkLogin();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.signInForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      organisation_name: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  checkLogin(): void {
    if (
      StorageService.getItem('accessToken') &&
      StorageService.getItem('self')
    ) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf(): void {
    this.authService.self({ all: true }).subscribe(
      (data) => {
        this.globalService.setSelf({
          name: data.name,
          email: data.email,
          contact: data.contact,
          user_id: data.id,
          username: data.username,
          user_type: data.user_type,
          organisation_id: data.organisation_id
        });
        this.router.navigateByUrl('/');
        this.updateLoginStatusActive();
      },
      (e) => {
        StorageService.clearAll();
        this.router.navigateByUrl('auth/login');
      }
    );
  }

  updateLoginStatusActive() {
    console.log('updateLoginStatusActive');
    this.userService.loginStatus({ login_status: 'ACTIVE' }).subscribe(
      (data) => {
        console.log('login_status active ', data);
      },
      (error) => {}
    );
  }

  login(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submitted) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('/home');
      } else {
        let params: {} = {
          username:
            this.loginForm.getRawValue() &&
            this.loginForm.getRawValue().username
              ? this.loginForm.getRawValue().username
              : '',
          password:
            this.loginForm.getRawValue() &&
            this.loginForm.getRawValue().password
              ? this.loginForm.getRawValue().password
              : ''
        };
        this.authService.login(params).subscribe(
          (data) => {
            console.log('Login response:', data);

            if (data && data.id) {
              this.globalService.setAccessToken(data?.token);
              this.globalService.setSelf({
                name: data?.name,
                email: data?.email,
                contact: data?.contact,
                user_id: data?.id,
                username: data?.username,
                user_type: data?.user_type,
                image: data?.image
              });
              this.navigationService.getSelfDetails();
              const checkout = localStorage.getItem('checkout');
              if (checkout) {
                this.router.navigateByUrl(checkout);
                this.loginForm.reset();
                localStorage.removeItem('checkout');
              } else {
                this.loginForm.reset();
                this.router.navigateByUrl('/home');

                this.connectToSocket();
              }
            }
          },
          error => {
            this.isBusy = false;
            this.submitted = false;
            if (error?.error?.type == 'RESOURCE_CREATION_FAILED'){
              this.alertService.showErrors(error.error.errorDetails)
            }
          }
        );
      }
    }
  }

  connectToSocket() {
    this.window.Pusher = Pusher;
    this.echo = new Echo({
      broadcaster: 'pusher',
      key: '93749af5fc1ecf9ab874',
      cluster: 'ap2',
      forceTLS: true
    });
    this.listenToChat();
  }

  listenToChat() {
    const self = StorageService.getItem('self');
    this.echo
      .channel(`chat_app_${self?.user_id}`)
      .listen('.message.sent', (data: any) => {
        // this.dataService.sendMessage(data);
        this.playNotificationSound();
      });
  }

  playNotificationSound() {
    console.log('playNotificationSound', this.userInteracted);
    if (this.userInteracted) {
      const audio = new Audio('assets/sounds/notification.mp3');
      audio
        .play()
        .catch((error) => console.error('Failed to play audio:', error));
    } else {
      console.warn(
        'Sound not played: user has not interacted with the document yet.'
      );
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData(): void {
    this.authService.self({ all: true }).subscribe(
      (data) => {
        this.isBusy = false;
        this.globalService.setSelf({
          name: data.name,
          email: data.email,
          contact: data.contact,
          user_id: data.id,
          username: data.username,
          user_type: data.user_type
        });
        return;
      },
      (e) => {
        this.isBusy = false;
        StorageService.clearAll();
      }
    );
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  loginFormVisible(): void {
    this.formType = 'login';
  }

  registerFormVisible(): void {
    this.formType = 'signIn';
  }

  /*otp(): void {
    this.submitted = true;
    if (!this.signInForm.valid) {
      return;
    }
    if (this.submitted) {
      const params: {} = {
        'organisation_name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().organisation_name) ? this.signInForm.getRawValue().organisation_name : '',
        'name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().name) ? this.signInForm.getRawValue().name : '',
        'email': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().email) ? this.signInForm.getRawValue().email : '',
        'contact': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().contact) ? this.signInForm.getRawValue().contact : '',
        'username': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().username) ? this.signInForm.getRawValue().username : '',
        'password': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().password) ? this.signInForm.getRawValue().username : ''
      };
      this.authService.otp(params).subscribe((data) => {
        if (data && data.decoded_otp) {
          this.signInParams = params;
          this.signInParams.decoded_otp = data.decoded_otp;
        }
      });
      this.formType = 'otpForm';
    }
  }*/

  /*signIn(): void {
    this.is_submitting = true;
    this.signInParams.otp = (this.otpForm && this.otpForm.getRawValue() && this.otpForm.getRawValue().otp) ? this.otpForm.getRawValue().otp : null;
    this.authService.register(this.signInParams).subscribe(data => {
        if (data && data.id) {
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'email': data.email,
            'contact': data.contact,
            'user_id': data.id,
            'username': data.username,
            'user_type': data.user_type,
            'organisation_id': data.organisation_id
          });
          this.otpForm.reset();
          this.router.navigateByUrl('/');
        }
      }
    );
    setTimeout(() => {
      this.is_submitting = false;
    }, 3000);
  }*/

  /*private _filter(value: string): any {
    const filterValue: any = value.toLowerCase();
    return this.organisations.filter(organisation => organisation.organisation_name.toLowerCase().includes(filterValue));
  }*/

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  protected readonly prompt = prompt;
}
