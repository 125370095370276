import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {
  private _messageSource = new BehaviorSubject<any>(null);
  message$ = this._messageSource.asObservable();
  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    public globalService: GlobalService
  ) {
    super(http, alertService, globalService);
  }

  sendMessage(message: any) {
    console.log(message);
    this._messageSource.next(message);
  }
}
