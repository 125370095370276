import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { AppUrl } from '../constants/app-url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BaseService {
  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    public globalService: GlobalService
  ) {
    super(http, alertService, globalService);
  }

  add(data: {}): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE(), data);
  }

  get(data: {}): Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE(), data);
  }

  getAllEmployees(data: {}): Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEES(), data);
  }

  detail(uniqueId: any): Observable<any>{
    return this.getRequest(AppUrl.EMPLOYEE(uniqueId));
  }

  status(data: {}, uniqueId: any): Observable<any>{
    return this.putRequest(AppUrl.EMPLOYEE_STATUS(uniqueId), data);
  }


  delete(uniqueId:any): Observable<any> {
    return this.deleteRequest(AppUrl.EMPLOYEE(uniqueId));
  }

  addEmployeeProfileImage(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PROFILE_IMAGE(uniqueId), data);

  }
  addGovtPhotoId(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_GOVT_PHOTO_ID(uniqueId), data);
  }

  addEmployeePan(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PAN(uniqueId), data);
  }
  addPersonalInfo(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_PERSONAL_INFO(uniqueId), data);
  }

  addAddressInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_ADDRESS_INFO(uniqueId), data);
  }

  addDepartmentAndDesignation(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_DEPARTMENT_DESIGNATION(uniqueId), data);
  }

  addAccountAndDetails(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_ACCOUNT_DETAILS(uniqueId), data)
  }

  addEmployeePayrollsDetails(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_PAYROLL_DETAILS(uniqueId), data)
  }

  addEmployeeLeaveDetails(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE_LEAVE_DETAILS(uniqueId), data)
  }
  addEmployeeTenthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_TENTH_CERTIFICATE(uniqueId), data)
  }


  addEmployeeTwelfthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_TWELFTH_CERTIFICATE(uniqueId), data);
  }
  addEmployeeGraduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_GRADUATION_CERTIFICATE(uniqueId), data);
  }
  addEmployeePostGraduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_POST_GRADUATION_CERTIFICATE(uniqueId), data);
  }
  addEmployeeHighestQualificationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_HIGHEST_QUALIFICATION_CERTIFICATE(uniqueId), data);
  }
  addOtherQualification(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_OTHER_QUALIFICATION_CERTIFICATE(uniqueId), data);
  }
  changePassword(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_CHANGE_PASSWORD(uniqueId), data);
  }
  getPayroll(data: {},uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PAYROLL(uniqueId), data);
  }

}
