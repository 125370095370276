<div class="container" [style.font-family]="'var(--vex-font)'">
  <div class="flex-auto flex items-center container subheading-2">
    <div class="py-4 grid grid-cols-1 sm:grid-cols-4 gap-3">
      <div class="py-3 flex items-center justify-center sm:col-span-1">
        <div class="text-center md:text-left">
          <img alt="Logo" src="./assets/images/brand/logo.png" class="mb-3 hidden md:block"/>
          <img alt="Logo" src="./assets/images/brand/logo.png" class="mx-auto mb-3 md:hidden lg:hidden xl:hidden"/>
          <h6 class="py-5">Partner with us for reliable and cost-effective Management services that keep your business
            running smoothly.</h6>
          <div class="flex flex-col md:flex-row md:justify-between">
            <p class="mt-5 mb-4 md:text-start text-center">Follow Us On:</p>
            <div class="flex justify-center md:justify-end">
              <div class="button-container-wrapper ">
                <div class="buttoncontainer">
                  <a class="button instagram" target="_blank" href="https://www.instagram.com/volminttechnologies/">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>

                <div class="buttoncontainer">
                  <a class="button twitter" target="_blank"
                     href="https://x.com/i/flow/login?redirect_after_login=%2FTechVolmint">
                    <i class="fab fa-twitter"></i>
                  </a>
                </div>

                <div class="buttoncontainer">
                  <a class="button linkedIn" target="_blank"
                     href="https://www.linkedin.com/company/volmint-technologies/">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>

                <div class="buttoncontainer">
                  <a class="button facebook" target="_blank" href="https://www.facebook.com/VolmintTechnologies">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 sm:col-span-2">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div class="text-center md:text-left">
            <h3 class="title text-center text-bold">Company</h3>
            <mat-list class="list text-center">
              <mat-list-item>
                <h3 class="cursor-pointer text-hover-effect" (click)="goTo('/about-us')">Who We Are</h3>
                <!--<h3 class="cursor-pointer text-hover-effect" [routerLink]="['/about-us']">Who We Are</h3>-->
              </mat-list-item>
              <mat-list-item>
                <h3 class="cursor-pointer text-hover-effect" (click)="goTo('/pricing-plans')">Pricing & Plans</h3>
              </mat-list-item>
              <mat-list-item>
                <h4 class="cursor-pointer text-hover-effect" (click)="goTo('/features')">Features</h4>
              </mat-list-item>
              <mat-list-item>
                <h3 class="cursor-pointer text-hover-effect" (click)="goTo('/blogs')">Blogs</h3>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="text-center md:text-left">
            <h3 class="title text-center text-bold">Resources</h3>
            <mat-list class="list text-center">
              <!--<mat-list-item>
                <h3 class="cursor-pointer text-hover-effect" (click)="goTo('/blogs')">Blogs</h3>
              </mat-list-item>-->
              <mat-list-item>
                <a (click)="goTo('/contact-us')" class="cursor-pointer text-hover-effect">Contact Us</a>
              </mat-list-item>
              <mat-list-item>
                <a (click)="goTo('/terms-conditions')" class="cursor-pointer text-hover-effect">Terms & Conditions</a>
              </mat-list-item>
              <mat-list-item>
                <a (click)="goTo('/refund-policy')" class="cursor-pointer text-hover-effect">Refund Policy</a>
              </mat-list-item>
              <!--  <mat-list-item>
                  <a (click)="goTo('/help-and-support')" class="cursor-pointer text-hover-effect">Help & Support</a>
                </mat-list-item>-->
            </mat-list>
          </div>
        </div>
      </div>
      <div class="py-4 sm:col-span-1">
        <div
          class="my-2 flex flex-col sm:flex-row items-center sm:items-start sm:justify-start sm:mx-0 sm:my-4 sm:flex">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 mb-3 sm:mb-0 sm:mr-3 flex items-center justify-center">
            <a href="tel:9310828501">
              <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
            </a>
          </div>
          <div class="text-center sm:text-left">
            <p class="m-0 body-1">Call Us</p>
            <p class="m-0 caption text-hint">+91 9310828501</p>
          </div>
        </div>
        <div
          class="my-4 flex flex-col sm:flex-row items-center sm:items-start sm:justify-start sm:mx-0 sm:my-4 sm:flex">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 mb-3 sm:mb-0 sm:mr-3 flex items-center justify-center">
            <a href="mailto:support@volmint.com">
              <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
            </a>
          </div>
          <div class="text-center sm:text-left">
            <p class="m-0 body-1">E-Mail Us</p>
            <p class="m-0 caption text-hint">support&#64;volmint.com</p>
          </div>
        </div>
        <div
          class="my-4 flex flex-col sm:flex-row items-center sm:items-start sm:justify-start sm:mx-0 sm:my-4 sm:flex">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 mb-3 sm:mb-0 sm:mr-3 flex items-center justify-center">
            <a
              href="https://www.google.com/maps/dir/28.5999104,77.08672/volmint+google+map/@28.6031852,77.0791628,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x390d1bf854143ecb:0x40321da1ddfa8234!2m2!1d77.0809699!2d28.6064602?entry=ttu">
              <mat-icon class="icon-sm" svgIcon="mat:location_on"></mat-icon>
            </a>
          </div>
          <div class="text-center sm:text-left">
            <p class="m-0 body-1">29/2, Vijay Enclave,<br>Dwarka-Palam Road,<br>Delhi-110045</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright mp-0 subheading-2" [style.font-family]="'var(--vex-font)'">
  <p class="mp-0 text-center py-3">
    © Copyright 2024. All rights reserved. <span class="text-info cursor-pointer"
                                                 (click)="openUrl('https://volmint.com')">Volmint Technologies Private Limited</span>
  </p>
</div>


